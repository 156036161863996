import React, { useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "react-bootstrap";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import "./login.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AuthService from "../../services/auth.service";
import VerigyOTPService from "../../services/verifyotp.service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const ForceChangePassword = () => {
  let navigate = useNavigate();

  const form = useRef();
  const checkBtn = useRef();

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");

  const [vid, setVid] = useState("");

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [dataUpload, setDataUpload] = useState(false);
  const [message, setMessage] = useState("");

  const [showOTP, setShowOTP] = useState(true);

  const [verifyOtp, setVerifyOtp] = useState(true);
  const [error, setError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const [isValid, setIsValid] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isCFocused, setIsCFocused] = useState(false);
  const [matched, setMatched] = useState(false);

  const onChangeOTP = (e) => {
    const otp = e.target.value;
    setOtp(otp);
  };

  const showOTPBox = () => {
    setShowOTP(true);
  };

  const onChangeNewPassword = (e) => {
    const password = e.target.value;
    setNewPassword(password);
    const passwordIsValid = validatePassword(password);
    setIsValid(passwordIsValid);
  };

  const validatePassword = (password) => {
    const user = AuthService.getCurrentUser();
    const regex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])(?=.*[a-zA-Z\d])(?!.*(?:GAA|gaa|Gaa)).{8,}$/;

    if (!regex.test(password)) {
      return false;
    }

    // if (
    //   password.toLowerCase().includes(user.fname.toLowerCase()) ||
    //   password.toUpperCase().includes(user.fname.toUpperCase()) ||
    //   password.toLowerCase().includes(user.lname.toLowerCase()) ||
    //   password.toUpperCase().includes(user.lname.toUpperCase())
    // ) {
    //   return false;
    // }

    if (
      (user.fname &&
        (password.toLowerCase().includes(user.fname.toLowerCase()) ||
          password.toUpperCase().includes(user.fname.toUpperCase()))) ||
      (user.lname &&
        (password.toLowerCase().includes(user.lname.toLowerCase()) ||
          password.toUpperCase().includes(user.lname.toUpperCase())))
    ) {
      return false;
    }

    // Check for consecutive numbers in ascending order (e.g., 123, 456)
    for (let i = 0; i < password.length - 2; i++) {
      const currentCharCode = password.charCodeAt(i);
      const nextCharCode = password.charCodeAt(i + 1);
      const afterNextCharCode = password.charCodeAt(i + 2);

      if (
        isDigit(currentCharCode) &&
        isDigit(nextCharCode) &&
        isDigit(afterNextCharCode) &&
        parseInt(password[i + 1]) - parseInt(password[i]) === 1 &&
        parseInt(password[i + 2]) - parseInt(password[i + 1]) === 1
      ) {
        return false;
      }
    }

    // Check for consecutive numbers in descending order (e.g., 321, 654)
    for (let i = 0; i < password.length - 2; i++) {
      const currentCharCode = password.charCodeAt(i);
      const nextCharCode = password.charCodeAt(i + 1);
      const afterNextCharCode = password.charCodeAt(i + 2);

      if (
        isDigit(currentCharCode) &&
        isDigit(nextCharCode) &&
        isDigit(afterNextCharCode) &&
        parseInt(password[i]) - parseInt(password[i + 1]) === 1 &&
        parseInt(password[i + 1]) - parseInt(password[i + 2]) === 1
      ) {
        return false;
      }
    }

    return true;
  };

  function isDigit(charCode) {
    return charCode >= 48 && charCode <= 57; // ASCII values for digits 0-9
  }

  const handleFocus = () => {
    if (isValid) {
      setIsFocused(false);
    } else {
      setIsFocused(true);
    }
  };

  const handleBlur = () => {
    if (isValid) {
      setIsFocused(false);
    } else {
      setIsFocused(true);
    }
  };

  const onChangeConfirmPassword = (e) => {
    const cpassword = e.target.value;
    setConfirmPassword(cpassword);
    if (newPassword && confirmPassword && newPassword === cpassword) {
      setMatched(true);
    } else {
      setMatched(false);
    }
  };

  const handleCFocus = () => {
    setIsCFocused(true);
  };

  const handleCBlur = () => {
    setIsCFocused(false);
  };

  const toggleNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const sendOTP = () => {
    if (isValid) {
      if (newPassword !== "" && confirmPassword !== "") {
        if (newPassword == confirmPassword) {
          VerigyOTPService.getEmail()
            .then((resp) => {
              console.log(resp.data);

              if (resp.data === true) {
                toast.success("An otp sent to your registered email id.", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
            })
            .catch((e) => console.log(e));

          setShowOTP(false);
        } else {
          setError(true);
          setErrMessage("Password not matched!");

          setTimeout(() => {
            setError(false);
          }, 3000);
        }
      } else {
        setError(true);
        setErrMessage("*Please fill all required fields!");

        setTimeout(() => {
          setError(false);
        }, 3000);
      }
    } else {
      toast.warn(
        "Password is not validate. Please enter passoword as per norms",
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };

  const changePassword = () => {
    if (newPassword !== "" && confirmPassword !== "") {
      if (newPassword == confirmPassword) {
        const data = {
          npass: newPassword,
          vaota: otp,
        };

        VerigyOTPService.verifyOTPPass(data)
          .then((resp) => {
            console.log(resp.data);
            if (resp.data === true) {
              toast.success("Password has been changed successfully.", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              localStorage.clear();
              navigate("/");
              window.location.reload();
            }
          })
          .catch((e) => console.log(e));
      } else {
        setError(true);
        setErrMessage("Password not matched!");

        setTimeout(() => {
          setError(false);
        }, 3000);
      }
    } else {
      setError(true);
      setErrMessage("*Please fill all required fields!");

      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };

  return (
    <>
      <div className="login_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <ToastContainer />
              <Form>
                <div className="login active">
                  <div className="heading">
                    {showOTP ? <h1>Change Password</h1> : <h1>Verify OTP</h1>}
                  </div>
                  {dataUpload ? (
                    <>
                      <Alert className="m-auto mb-5" variant="success">
                        {message}
                      </Alert>
                    </>
                  ) : (
                    <></>
                  )}
                  {error ? (
                    <>
                      <Alert className="m-auto mb-5" variant="danger">
                        {errMessage}
                      </Alert>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="form">
                    {showOTP ? (
                      <>
                        <div className="form-group togglePassword">
                          <input
                            type={showNewPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            className="input password"
                            required
                            value={newPassword}
                            onChange={onChangeNewPassword}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            validations={[required]}
                            min={8}
                          />
                          <label htmlFor="">New Password *</label>
                          <i
                            className={
                              showNewPassword
                                ? "uil uil-eye"
                                : "uil uil-eye-slash"
                            }
                            id="eye-icon"
                            onClick={toggleNewPassword}
                          ></i>
                        </div>
                        {isFocused && !isValid && (
                          <p style={{ color: "#ed4337", fontWeight: "bold" }}>
                            Password must contain alpha-numeric with at least
                            one special character, one numeric, atleast 8
                            characters long, not containing your first or
                            lastname, and not continues letters or numbers.
                          </p>
                        )}
                        {isFocused && isValid && (
                          <p style={{ color: "#49b618", fontWeight: "bold" }}>
                            Password is valid!
                          </p>
                        )}

                        <div className="form-group togglePassword">
                          <input
                            type={showConfirmPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            className="input password"
                            required
                            value={confirmPassword}
                            onChange={onChangeConfirmPassword}
                            onFocus={handleCFocus}
                            onBlur={handleCBlur}
                            validations={[required]}
                          />
                          <label htmlFor="">Confirm Password *</label>
                          <i
                            className={
                              showConfirmPassword
                                ? "uil uil-eye"
                                : "uil uil-eye-slash"
                            }
                            id="eye-icon"
                            onClick={toggleConfirmPassword}
                          ></i>
                        </div>
                        {isCFocused && !isValid && (
                          <p style={{ color: "#ed4337", fontWeight: "bold" }}>
                            Please enter Password!
                          </p>
                        )}
                        {isCFocused && isValid && matched && (
                          <p style={{ color: "#49b618", fontWeight: "bold" }}>
                            Passwords are matching!
                          </p>
                        )}
                        {isValid && !matched && (
                          <p style={{ color: "#ed4337", fontWeight: "bold" }}>
                            Passwords are not matching!
                          </p>
                        )}
                        <div className="form-group action">
                          <div className="flex_form-group">
                            <button
                              className="fill_btn"
                              type="button"
                              name="btn_login"
                              onClick={sendOTP}
                            >
                              <span>Submit</span>
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="form-group togglePassword otp active">
                          <input
                            type="text"
                            id="otp"
                            name="otp"
                            className="input password"
                            required
                            value={otp}
                            onChange={onChangeOTP}
                            validations={[required]}
                          />
                          <label htmlFor="">OTP</label>
                        </div>
                        <div className="form-group action">
                          <div className="flex_form-group">
                            <button
                              className="fill_btn"
                              type="button"
                              name="btn_login"
                              onClick={changePassword}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <CheckButton style={{ display: "none" }} ref={checkBtn} />
              </Form>
            </div>
            <div className="col-xl-6 col-lg-6"></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ForceChangePassword;
