import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
// import "../SuperAdmin/Dashboard/dashboard.css";
import "./learn.css";
import GraphService from "../../services/graph.service";

import Loading from "../../assets/images/Loading.gif";

Chart.register(...registerables);

function MyProgressChart() {
  const [resolvedReq, setResolvedReq] = useState("20");
  const [unresolvedReq, setUnresolvedReq] = useState("12");
  const [inprogressReq, setInprogressReq] = useState("9");

  const [courses, setCourses] = useState([]);
  const [marks, setMarks] = useState([]);

  const [combineProgress, setCombineProgress] = useState([]);

  useEffect(() => {
    GraphService.getStudentProgress()
      .then((resp) => {
        setCourses(resp.data.distrubuted.courses);
        setMarks(resp.data.distrubuted.grades);
        setCombineProgress(resp.data.combine);
        console.log(resp.data.distrubuted.grades);
      })
      .catch((e) => console.log(e));
  }, []);

  const data = {
    labels: courses,
    datasets: [
      {
        label: "",
        data: marks,
        backgroundColor: ["#0058FF", "#21D59B", "#FFC700", "#F99600"],
        borderColor: ["transparent"],
        hoverOffset: 4,
      },
    ],
  };

  const centerText = {
    id: "",
  };

  const options = {
    cutout: 75,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      centerText: [centerText],
    },
  };

  return (
    <div
      className={
        marks.length > 0 ? "main-donut-container" : "main-donut-container gap0"
      }
    >
      <h6 style={{ textAlign: "left" }}>My Progress</h6>
      {marks.length > 0 ? (
        <>
          <div className="donut-container">
            <div>
              <Doughnut
                data={data}
                options={options}
                width="170px"
                height="170px"
              />
            </div>
            <div className="donut-data">
              {Array.isArray(combineProgress) ? (
                combineProgress.map((combProgress, index) => {
                  return (
                    <div className="donutDetails" key={index}>
                      <div className="donutDetails-flex">
                        <div
                          className={`donutDetails-color do-color${index + 1}`}
                        ></div>
                        <span title={combProgress.coursename}>
                          {combProgress.coursename
                            .split(" ")
                            .map((word) => word.charAt(0))
                            .join("")}
                        </span>
                      </div>
                      <span>{combProgress.max_grade}%</span>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="inner-box">
            <div className="loading">
              <h1>No Progress yet</h1>
              <img src={Loading} width="200px" alt="" />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default MyProgressChart;
