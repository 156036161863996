import React, { useState, useEffect } from "react";
import "./CourseManagement.css";
import "./responsive.css";
import { Form, FloatingLabel } from "react-bootstrap";
import CourseImg from "../../../assets/images/course-placeholder.png";
import { Link, useLocation } from "react-router-dom";
import AuthService from "../../../services/auth.service";
import CourseService from "../../../services/course.service";
import CoursecatService from "../../../services/coursecat.service";
import Select from "react-select";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EstoreService from "../../../services/estore.service";

const EditCourse = () => {
  const location = useLocation();
  const { cid, isEstore } = location.state;
  const [validated, setValidated] = useState(false);

  const [changeData, setChangeData] = useState(false);

  const [uploaded, setUploaded] = useState(false);

  const [courseCat, setCourseCat] = useState({});

  const [courseimgPreview, setCourseimgPreview] = useState("");

  const [selectedCourseCat, setSelectedCourseCat] = useState();

  const [userId, setUserId] = useState("");

  const [error, setError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const [courseLMS, setCourseLMS] = useState({
    courseId: cid,
    courseimg: "",
    courseName: "",
    startDate: "",
    endDate: "",
    category: "",
    description: "",
  });

  const [courseEstore, setCourseEstore] = useState({
    course_id: cid,
    courseimg: "",
    courseName: "",
    cexpdate: "",
    description: "",
    price: "",
  });

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    getEstoreCoursesById();

    CoursecatService.getCourseCat(user.user)
      .then((response) => {
        setCourseCat(
          response.data.map((coursecat) => ({
            value: coursecat.id,
            label: coursecat.course_catename,
          }))
        );
      })
      .catch((e) => {
        console.log(e);
      });

    CourseService.getCourseById(user.user, cid)
      .then((response) => {
        setCourseLMS({
          user_id: user.user,
          courseId: cid,
          courseimg: response.data.course_info[0].course_image,
          courseName: response.data.course_info[0].name,
          startDate: response.data.course_info[0].start_date,
          endDate: response.data.course_info[0].end_date,
          category: response.data.course_info[0].category_id,
          description: response.data.course_info[0].description,
        });

        setSelectedCourseCat(response.data.course_info[0].category_id);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const getEstoreCoursesById = () => {
    const data = {
      corsid: cid,
    };
    EstoreService.getEstoreCourseById(data)
      .then((resp) => {
        setCourseEstore({
          course_id: resp.data[0].course_id,
          courseimg: resp.data[0].corsimg,
          courseName: resp.data[0].coursename,
          cexpdate: resp.data[0].cexpdate,
          description: resp.data[0].description,
          price: resp.data[0].price,
        });
      })
      .catch((e) => console.log(e));
  };

  const handleLMSInputChange = (e) => {
    const { name, value } = e.target;
    setCourseLMS({ ...courseLMS, [name]: value });
    setChangeData(true);
  };

  const handleEStoreInputChange = (e) => {
    const { name, value } = e.target;
    setCourseEstore({ ...courseEstore, [name]: value });
    setChangeData(true);
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    setCourseLMS({ ...courseLMS, [name]: e.target.files[0] });
    setCourseEstore({ ...courseEstore, [name]: e.target.files[0] });
    setCourseimgPreview(URL.createObjectURL(e.target.files[0]));
    setUploaded(true);
    setChangeData(true);
  };

  const handleCourseCat = (selectedCourseCat) => {
    setSelectedCourseCat(selectedCourseCat);
    const value = selectedCourseCat ? selectedCourseCat.value : "";
    setCourseLMS({ ...courseLMS, category: value });
    setChangeData(true);
  };

  //LMS
  const handleCourseLMSSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      if (changeData) {
        toast.warn("*Please fill all required fields!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

      return;
    }

    const storedCourseData = localStorage.getItem("coursesdata");
    if (storedCourseData) {
      const localData = JSON.parse(storedCourseData);
      const names = localData.map((res) => res.name);
      const ids = localData.map((res) => res.id);

      if (
        changeData &&
        names.includes(courseLMS.courseName) &&
        !ids.includes(cid)
      ) {
        setError(true);
        // setErrMessage("Course name already exists!");
        toast.warn("Course name already exists!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setError(false);
        setErrMessage("");
      }
    }

    const formData = new FormData();

    formData.append("courseid", cid);
    formData.append("coursename", courseLMS.courseName);
    formData.append("cateid", courseLMS.category);
    formData.append("description", courseLMS.description);
    formData.append("startDate", courseLMS.startDate);
    formData.append("endDate", courseLMS.endDate);
    formData.append("course_image", courseLMS.courseimg);
    formData.append("usp", userId);

    if (changeData && !error) {
      CourseService.addCourseDetails(formData).then((response) => {
        toast.success("Course Details Updated Successfully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
    }
  };

  // EStore
  const handleCourseEstoreSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    const storedCourseData = localStorage.getItem("coursesdata");

    if (storedCourseData) {
      const localData = JSON.parse(storedCourseData);
      const names = localData.map((res) => res.name);

      if (changeData && names.includes(courseLMS.courseName)) {
        setError(true);
        // setErrMessage("Course name already exists!");
        toast.warn("Course name already exists!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setError(false);
        setErrMessage("");
      }
    }

    const data = {
      corsid: cid,
      description: courseEstore.description,
      price: courseEstore.price,
      cexpdate: courseEstore.cexpdate,
    };

    if (changeData && !error) {
      EstoreService.updateEstoreCourses(data).then((response) => {
        toast.success("Estore Course Details Updated Successfully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="box-outer editCourse">
              <div className="box-inner">
                <div className="action-flex">
                  <div className="heading">
                    <h1>Edit Course</h1>
                  </div>
                  <div className="courses-act">
                    <Link to="/ManageCourses" className="action-item">
                      <div>
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 19.379 19.382"
                          >
                            <g
                              id="left-arrow-in-circular-button-black-symbol"
                              transform="translate(-0.6 511.567)"
                            >
                              <path
                                id="Path_13057"
                                data-name="Path 13057"
                                d="M9.381-511.546A9.911,9.911,0,0,0,4.162-509.4a11.83,11.83,0,0,0-1.457,1.468,10.165,10.165,0,0,0-1.878,3.88A8.145,8.145,0,0,0,.6-501.873a8.145,8.145,0,0,0,.228,2.181,10.088,10.088,0,0,0,1.931,3.945,11.828,11.828,0,0,0,1.468,1.457,10.164,10.164,0,0,0,3.88,1.878,8.146,8.146,0,0,0,2.181.228,8.146,8.146,0,0,0,2.181-.228,10.541,10.541,0,0,0,3.141-1.339A9.712,9.712,0,0,0,19-497.606a9.626,9.626,0,0,0,.914-2.94,12.941,12.941,0,0,0,0-2.655,9.626,9.626,0,0,0-.914-2.94,9.19,9.19,0,0,0-1.889-2.625,9.01,9.01,0,0,0-2.553-1.813,9.581,9.581,0,0,0-2.9-.91A14.607,14.607,0,0,0,9.381-511.546Zm1.1,5.618a.61.61,0,0,1,.25.766c-.03.068-.622.69-1.328,1.392L8.126-502.5l3.315.019c3.277.019,3.319.019,3.421.1a.536.536,0,0,1,.262.508.536.536,0,0,1-.262.508c-.1.08-.144.08-3.421.1l-3.315.019L9.4-499.977c.706.7,1.3,1.324,1.328,1.392a.607.607,0,0,1-.77.808c-.14-.053-3.683-3.588-3.751-3.744a1.386,1.386,0,0,1-.015-.683c.053-.133,3.581-3.679,3.736-3.751A.637.637,0,0,1,10.477-505.928Z"
                                transform="translate(0 0)"
                              />
                            </g>
                          </svg>
                        </span>
                        &nbsp;&nbsp;
                        <span>Back</span>
                      </div>
                    </Link>
                  </div>
                </div>

                {isEstore ? (
                  <>
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleCourseEstoreSubmit}
                    >
                      <div className="course-img-flex">
                        <div
                          className="bulk-upload-area"
                          style={{ width: 300, height: 170 }}
                        >
                          <input
                            type="file"
                            id=""
                            name="courseimg"
                            onChange={handleFileChange}
                            title="Disbaled"
                            disabled
                          />
                          {uploaded === true ? (
                            <>
                              <img
                                src={courseimgPreview}
                                className="img-fluid"
                                alt=""
                              />
                            </>
                          ) : (
                            <>
                              {courseEstore.courseimg ? (
                                <>
                                  <img
                                    src={
                                      "http://lmsdemo.aeonsoftware.net/cit/" +
                                      courseEstore.courseimg
                                    }
                                    className="img-fluid"
                                    alt=""
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    src={CourseImg}
                                    className="img-fluid"
                                    alt=""
                                  />
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="add-user-form">
                        <div className="form-flex">
                          <div className="mb-3">
                            <Form.Group controlId="formBasicName">
                              <div className="input-flex">
                                <div className="text-input">
                                  <Form.Label>Course Name</Form.Label>
                                  <div className="input-box">
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Course Name"
                                      name="courseName"
                                      onChange={handleEStoreInputChange}
                                      value={courseEstore.courseName}
                                      required
                                      disabled
                                    />
                                    <span className="icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 20.199 20.201"
                                      >
                                        <g
                                          id="Group_11227"
                                          data-name="Group 11227"
                                          transform="translate(-2402.326 -6200.833)"
                                        >
                                          <path
                                            id="Path_11890"
                                            data-name="Path 11890"
                                            d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                                            transform="translate(752.656 5510.435)"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                          />
                                          <path
                                            id="Path_11892"
                                            data-name="Path 11892"
                                            d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                                            transform="translate(751.221 5511.583)"
                                          />
                                        </g>
                                      </svg>
                                    </span>
                                  </div>
                                </div>
                                <div className="date-input flex-col">
                                  <Form.Label>Expiry Date</Form.Label>
                                  <div className="input-box">
                                    <Form.Control
                                      type="date"
                                      name="cexpdate"
                                      onChange={handleEStoreInputChange}
                                      value={courseEstore.cexpdate}
                                      required
                                    />
                                    <span className="icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="14.02"
                                        viewBox="0 0 81.923 76.58"
                                      >
                                        <g
                                          id="calendar"
                                          transform="translate(-15 482)"
                                        >
                                          <path
                                            id="Path_13206"
                                            data-name="Path 13206"
                                            d="M20.071-481.763a6.872,6.872,0,0,0-4.139,3.494c-.8,1.577-.933,2.68-.933,8.175v5.054H96.77l-.051-5.394-.051-5.377-.543-1.153a9.468,9.468,0,0,0-6.31-4.8A33.656,33.656,0,0,0,84.592-482H80.47v3.85a39.256,39.256,0,0,1-.17,4.461,3,3,0,0,1-.763,1.187,1.965,1.965,0,0,1-1.476.645,1.8,1.8,0,0,1-1.476-.339c-1.289-.882-1.289-.865-1.34-5.58L75.179-482H34.505v3.681c-.017,4.427-.1,4.987-.9,5.767a2.54,2.54,0,0,1-3.9-.441c-.356-.577-.373-.814-.424-4.817L29.23-482l-4.189.017A31.215,31.215,0,0,0,20.071-481.763Z"
                                          />
                                          <path
                                            id="Path_13207"
                                            data-name="Path 13207"
                                            d="M15.034-326.627l.051,24.39.441,1.119a6.553,6.553,0,0,0,4.122,4.172c.814.288,2.612.305,35.551.305H89.884l1.136-.39a9.341,9.341,0,0,0,5.275-4.444l.543-1.1.051-24.2L96.923-351H15ZM38.9-341.281a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.935.051,3.155.034,3.528-.271,4.223-.594,1.34-.746,1.374-5.054,1.374-5.343,0-5.156.2-5.156-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,38.9-341.281Zm20.184,0a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.986.068,3.9-.051,4.58-.865,5.19-.458.339-.7.356-4.359.356-5.462,0-5.258.22-5.258-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,59.082-341.281Zm20.54.034c.933.492,1.018.882,1.018,5,0,5.445.271,5.173-5.241,5.173-3.731,0-3.85-.017-4.376-.39-.78-.56-.9-1.187-.9-4.919,0-3.562.1-4.1.933-4.749.458-.356.628-.373,4.274-.373C78.4-341.5,79.232-341.451,79.622-341.247ZM39.2-318.129c.78.56.9,1.187.9,4.834,0,3.46-.1,3.935-.916,4.7-.458.424-.475.424-4.274.475-3.613.051-3.85.034-4.376-.305-.916-.56-.967-.882-.916-5.139.051-3.782.051-3.833.458-4.257a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C38.508-318.519,38.678-318.5,39.2-318.129Zm20.082-.085c.848.577.916.9.916,4.851a36.048,36.048,0,0,1-.136,4.139,1.659,1.659,0,0,1-.678.8c-.492.305-.865.339-4.325.339-5.563,0-5.326.237-5.258-5.428.051-3.85.051-3.884.458-4.308a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C58.37-318.519,58.9-318.485,59.286-318.214Zm19.845-.221a2.965,2.965,0,0,1,1.018.611l.407.441v8.277l-.475.458-.458.475H71.345l-.441-.407c-.712-.678-.814-1.34-.746-5.139.068-4.953-.017-4.868,5.139-4.868A28.455,28.455,0,0,1,79.131-318.434Z"
                                            transform="translate(0 -108.781)"
                                          />
                                        </g>
                                      </svg>
                                    </span>
                                  </div>
                                </div>
                                <div className="date-input flex-col">
                                  <Form.Label>Price</Form.Label>
                                  <div className="input-box">
                                    <Form.Control
                                      type="number"
                                      placeholder="Enter Price"
                                      name="price"
                                      value={courseEstore.price}
                                      onChange={handleEStoreInputChange}
                                      required
                                    />
                                    <span className="icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 20.199 20.201"
                                      >
                                        <g
                                          id="Group_11227"
                                          data-name="Group 11227"
                                          transform="translate(-2402.326 -6200.833)"
                                        >
                                          <path
                                            id="Path_11890"
                                            data-name="Path 11890"
                                            d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                                            transform="translate(752.656 5510.435)"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                          />
                                          <path
                                            id="Path_11892"
                                            data-name="Path 11892"
                                            d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                                            transform="translate(751.221 5511.583)"
                                          />
                                        </g>
                                      </svg>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                        </div>

                        <Form.Group
                          className="mb-3"
                          controlId="formBasicDesignation"
                        >
                          <Form.Label>Description</Form.Label>
                          <div className="input-box">
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              name="description"
                              value={courseEstore.description}
                              onChange={handleEStoreInputChange}
                            ></textarea>
                          </div>
                        </Form.Group>
                      </div>
                      <ToastContainer />
                      <div className="act-btn">
                        <button disabled={!changeData}>Submit</button>
                      </div>
                    </Form>
                  </>
                ) : (
                  <>
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleCourseLMSSubmit}
                    >
                      <div className="course-img-flex">
                        <div className="bulk-upload-area">
                          <input
                            type="file"
                            id=""
                            name="courseimg"
                            onChange={handleFileChange}
                            title="Change Course Image"
                          />
                          {uploaded === true ? (
                            <>
                              <img
                                src={courseimgPreview}
                                className="img-fluid"
                                alt=""
                              />
                            </>
                          ) : (
                            <>
                              {courseLMS.courseimg ? (
                                <>
                                  <img
                                    src={
                                      "http://lmsdemo.aeonsoftware.net/cit/" +
                                      courseLMS.courseimg
                                    }
                                    className="img-fluid"
                                    alt=""
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    src={CourseImg}
                                    className="img-fluid"
                                    alt=""
                                  />
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="add-user-form">
                        <div className="form-flex">
                          <div className="mb-3">
                            <Form.Group controlId="formBasicName">
                              <Form.Label>Course Name</Form.Label>
                              <div className="input-flex">
                                <div className="text-input">
                                  <div className="input-box">
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Course Name"
                                      name="courseName"
                                      onChange={handleLMSInputChange}
                                      value={courseLMS.courseName}
                                      required
                                    />
                                    <span className="icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 20.199 20.201"
                                      >
                                        <g
                                          id="Group_11227"
                                          data-name="Group 11227"
                                          transform="translate(-2402.326 -6200.833)"
                                        >
                                          <path
                                            id="Path_11890"
                                            data-name="Path 11890"
                                            d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                                            transform="translate(752.656 5510.435)"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                          />
                                          <path
                                            id="Path_11892"
                                            data-name="Path 11892"
                                            d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                                            transform="translate(751.221 5511.583)"
                                          />
                                        </g>
                                      </svg>
                                    </span>
                                  </div>
                                </div>
                                <div className="date-input">
                                  <div className="input-box">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Start Date"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="date"
                                        name="startDate"
                                        onChange={handleLMSInputChange}
                                        value={courseLMS.startDate}
                                        required
                                      />
                                    </FloatingLabel>
                                    <span className="icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="14.02"
                                        viewBox="0 0 81.923 76.58"
                                      >
                                        <g
                                          id="calendar"
                                          transform="translate(-15 482)"
                                        >
                                          <path
                                            id="Path_13206"
                                            data-name="Path 13206"
                                            d="M20.071-481.763a6.872,6.872,0,0,0-4.139,3.494c-.8,1.577-.933,2.68-.933,8.175v5.054H96.77l-.051-5.394-.051-5.377-.543-1.153a9.468,9.468,0,0,0-6.31-4.8A33.656,33.656,0,0,0,84.592-482H80.47v3.85a39.256,39.256,0,0,1-.17,4.461,3,3,0,0,1-.763,1.187,1.965,1.965,0,0,1-1.476.645,1.8,1.8,0,0,1-1.476-.339c-1.289-.882-1.289-.865-1.34-5.58L75.179-482H34.505v3.681c-.017,4.427-.1,4.987-.9,5.767a2.54,2.54,0,0,1-3.9-.441c-.356-.577-.373-.814-.424-4.817L29.23-482l-4.189.017A31.215,31.215,0,0,0,20.071-481.763Z"
                                          />
                                          <path
                                            id="Path_13207"
                                            data-name="Path 13207"
                                            d="M15.034-326.627l.051,24.39.441,1.119a6.553,6.553,0,0,0,4.122,4.172c.814.288,2.612.305,35.551.305H89.884l1.136-.39a9.341,9.341,0,0,0,5.275-4.444l.543-1.1.051-24.2L96.923-351H15ZM38.9-341.281a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.935.051,3.155.034,3.528-.271,4.223-.594,1.34-.746,1.374-5.054,1.374-5.343,0-5.156.2-5.156-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,38.9-341.281Zm20.184,0a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.986.068,3.9-.051,4.58-.865,5.19-.458.339-.7.356-4.359.356-5.462,0-5.258.22-5.258-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,59.082-341.281Zm20.54.034c.933.492,1.018.882,1.018,5,0,5.445.271,5.173-5.241,5.173-3.731,0-3.85-.017-4.376-.39-.78-.56-.9-1.187-.9-4.919,0-3.562.1-4.1.933-4.749.458-.356.628-.373,4.274-.373C78.4-341.5,79.232-341.451,79.622-341.247ZM39.2-318.129c.78.56.9,1.187.9,4.834,0,3.46-.1,3.935-.916,4.7-.458.424-.475.424-4.274.475-3.613.051-3.85.034-4.376-.305-.916-.56-.967-.882-.916-5.139.051-3.782.051-3.833.458-4.257a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C38.508-318.519,38.678-318.5,39.2-318.129Zm20.082-.085c.848.577.916.9.916,4.851a36.048,36.048,0,0,1-.136,4.139,1.659,1.659,0,0,1-.678.8c-.492.305-.865.339-4.325.339-5.563,0-5.326.237-5.258-5.428.051-3.85.051-3.884.458-4.308a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C58.37-318.519,58.9-318.485,59.286-318.214Zm19.845-.221a2.965,2.965,0,0,1,1.018.611l.407.441v8.277l-.475.458-.458.475H71.345l-.441-.407c-.712-.678-.814-1.34-.746-5.139.068-4.953-.017-4.868,5.139-4.868A28.455,28.455,0,0,1,79.131-318.434Z"
                                            transform="translate(0 -108.781)"
                                          />
                                        </g>
                                      </svg>
                                    </span>
                                  </div>
                                  <div className="input-box">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="End Date"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="date"
                                        name="endDate"
                                        onChange={handleLMSInputChange}
                                        value={courseLMS.endDate}
                                        required
                                      />
                                    </FloatingLabel>
                                    <span className="icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="14.02"
                                        viewBox="0 0 81.923 76.58"
                                      >
                                        <g
                                          id="calendar"
                                          transform="translate(-15 482)"
                                        >
                                          <path
                                            id="Path_13206"
                                            data-name="Path 13206"
                                            d="M20.071-481.763a6.872,6.872,0,0,0-4.139,3.494c-.8,1.577-.933,2.68-.933,8.175v5.054H96.77l-.051-5.394-.051-5.377-.543-1.153a9.468,9.468,0,0,0-6.31-4.8A33.656,33.656,0,0,0,84.592-482H80.47v3.85a39.256,39.256,0,0,1-.17,4.461,3,3,0,0,1-.763,1.187,1.965,1.965,0,0,1-1.476.645,1.8,1.8,0,0,1-1.476-.339c-1.289-.882-1.289-.865-1.34-5.58L75.179-482H34.505v3.681c-.017,4.427-.1,4.987-.9,5.767a2.54,2.54,0,0,1-3.9-.441c-.356-.577-.373-.814-.424-4.817L29.23-482l-4.189.017A31.215,31.215,0,0,0,20.071-481.763Z"
                                          />
                                          <path
                                            id="Path_13207"
                                            data-name="Path 13207"
                                            d="M15.034-326.627l.051,24.39.441,1.119a6.553,6.553,0,0,0,4.122,4.172c.814.288,2.612.305,35.551.305H89.884l1.136-.39a9.341,9.341,0,0,0,5.275-4.444l.543-1.1.051-24.2L96.923-351H15ZM38.9-341.281a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.935.051,3.155.034,3.528-.271,4.223-.594,1.34-.746,1.374-5.054,1.374-5.343,0-5.156.2-5.156-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,38.9-341.281Zm20.184,0a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.986.068,3.9-.051,4.58-.865,5.19-.458.339-.7.356-4.359.356-5.462,0-5.258.22-5.258-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,59.082-341.281Zm20.54.034c.933.492,1.018.882,1.018,5,0,5.445.271,5.173-5.241,5.173-3.731,0-3.85-.017-4.376-.39-.78-.56-.9-1.187-.9-4.919,0-3.562.1-4.1.933-4.749.458-.356.628-.373,4.274-.373C78.4-341.5,79.232-341.451,79.622-341.247ZM39.2-318.129c.78.56.9,1.187.9,4.834,0,3.46-.1,3.935-.916,4.7-.458.424-.475.424-4.274.475-3.613.051-3.85.034-4.376-.305-.916-.56-.967-.882-.916-5.139.051-3.782.051-3.833.458-4.257a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C38.508-318.519,38.678-318.5,39.2-318.129Zm20.082-.085c.848.577.916.9.916,4.851a36.048,36.048,0,0,1-.136,4.139,1.659,1.659,0,0,1-.678.8c-.492.305-.865.339-4.325.339-5.563,0-5.326.237-5.258-5.428.051-3.85.051-3.884.458-4.308a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C58.37-318.519,58.9-318.485,59.286-318.214Zm19.845-.221a2.965,2.965,0,0,1,1.018.611l.407.441v8.277l-.475.458-.458.475H71.345l-.441-.407c-.712-.678-.814-1.34-.746-5.139.068-4.953-.017-4.868,5.139-4.868A28.455,28.455,0,0,1,79.131-318.434Z"
                                            transform="translate(0 -108.781)"
                                          />
                                        </g>
                                      </svg>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Category</Form.Label>
                          <div className="input-box">
                            <Select
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  border: "none",
                                  outline: "none",
                                  borderRadius: "10px",
                                  backgroundColor: state.isFocused
                                    ? "var(--dark-bg)"
                                    : "var(--dark-bg)",
                                  height: "45px",
                                }),
                                option: (
                                  baseStyles,
                                  { isFocused, isSelected }
                                ) => ({
                                  ...baseStyles,
                                  backgroundColor: isSelected
                                    ? "var(--primary-color)"
                                    : isFocused
                                    ? "var(--secondary-color)"
                                    : "var(--body-dark-bg)",
                                  color: isSelected
                                    ? "var(--body-dark-bg)"
                                    : isFocused
                                    ? "var(--body-dark-bg)"
                                    : "var(--whiteColor)",
                                }),
                              }}
                              // className="react-select-container"
                              // classNamePrefix="react-select"
                              onChange={handleCourseCat}
                              // value={selectedCourseCat}
                              options={courseCat}
                              issearchable="true"
                              placeholder="Select Course Category"
                              value={
                                Array.isArray(courseCat)
                                  ? courseCat.filter(
                                      (option) =>
                                        courseLMS.category === option.value
                                    )
                                  : []
                              }
                              required
                            />
                          </div>
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="formBasicDesignation"
                        >
                          <Form.Label>Description</Form.Label>
                          <div className="input-box">
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              name="description"
                              value={courseLMS.description}
                              onChange={handleLMSInputChange}
                            ></textarea>
                          </div>
                        </Form.Group>
                      </div>
                      <ToastContainer />
                      <div className="act-btn">
                        <button disabled={!changeData}>Submit</button>
                      </div>
                    </Form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCourse;
