import React, { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import EventService from "../services/event.service";
import Loading from "../assets/images/Loading.gif";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-bootstrap";

const AllEvents = () => {
  const [allEvents, setAllEvents] = useState([]);
  const [show, setShow] = useState(false);
  const [eventId, setEventId] = useState("");
  const locaion = useLocation();
  const formatDate = (dateStr) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    const [year, month, day] = dateStr.split("-");
    const monthName = months[parseInt(month, 10) - 1];
    let newDate = `${day} ${monthName}`;
    return newDate;
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      getAllEvents(user.user);
    }
  }, []);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (e, envid) => {
    e.preventDefault();
    setEventId(envid);
    setShow(true);
  };

  const getAllEvents = (uid) => {
    EventService.getEventById(uid).then((response) => {
      const disEvent = Array.isArray(response.data)
        ? response.data.map((data) => ({
            id: data.id,
            title: data.title,
            start: data.date,
            description: data.description,
          }))
        : null;

      if (disEvent) {
        const filtereddata = disEvent
          .filter((data) => {
            const now = new Date();
            const targetDate = new Date(data.start);
            return targetDate > now; // Filter out past events
          })
          .sort((a, b) => new Date(a.start) - new Date(b.start));

        setAllEvents(filtereddata);
      }
    });
  };

  const deleteEvent = () => {
    const data = {
      envid: eventId,
    };
    EventService.delEvent(data)
      .then((resp) => {
        if (resp.data === false) {
          toast.warn("The Event is not created by you!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          const user = AuthService.getCurrentUser();
          getAllEvents(user.user);
          toast.success("Event deleted successfully", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <div className="assignments">
        <ToastContainer />
        {Array.isArray(allEvents) && allEvents.length > 0 ? (
          allEvents.map((data) => (
            <div className="assignments-item" key={data.id}>
              <div className="date">
                <span>{formatDate(data.start)}</span>
              </div>
              <div className="info">
                <span className="cname">{data.title}</span>
              </div>
              <div className="">
                <button
                  type="button"
                  class="btn-closes"
                  onClick={(e) => {
                    handleShow(e, data.id);
                  }}
                  title="Delete Event"
                >
                  <svg
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 512.000000 512.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                      stroke="none"
                    >
                      <path d="M2226 4469 c-62 -15 -153 -68 -197 -116 -65 -71 -101 -158 -107 -263 l-5 -89 -388 -3 c-374 -3 -389 -4 -415 -24 -67 -49 -69 -58 -69 -254 0 -196 2 -205 69 -254 27 -21 29 -21 1446 -21 1417 0 1419 0 1446 21 67 49 69 58 69 254 0 196 -2 205 -69 254 -26 20 -41 21 -415 24 l-388 3 -5 89 c-10 161 -86 278 -226 348 l-76 37 -315 2 c-174 1 -333 -3 -355 -8z m629 -334 c21 -20 25 -34 25 -80 l0 -55 -320 0 -320 0 0 55 c0 46 4 60 25 80 l24 25 271 0 271 0 24 -25z" />
                      <path d="M1315 3266 c-16 -8 -43 -28 -59 -45 -24 -25 -30 -42 -34 -88 -3 -56 178 -1997 198 -2118 29 -173 183 -325 368 -364 74 -15 1471 -15 1544 0 181 39 327 176 362 342 21 99 209 2079 204 2145 -5 59 -38 104 -95 128 -47 20 -2443 20 -2488 0z m858 -419 c64 -49 61 -30 112 -827 56 -895 55 -856 25 -905 -51 -82 -168 -98 -238 -32 -52 49 -51 37 -103 861 -28 429 -47 795 -44 813 19 106 161 157 248 90z m963 -2 c72 -55 71 -12 20 -839 -25 -408 -49 -767 -52 -798 -13 -130 -126 -203 -231 -149 -63 32 -88 81 -86 167 4 125 93 1508 99 1537 7 36 50 86 89 103 46 22 117 12 161 -21z" />
                    </g>
                  </svg>
                </button>
              </div>
            </div>
          ))
        ) : (
          <>
            <div className="no-evnets">
              <h1>No Events scheduled in this month</h1>
              <img src={Loading} width="200px" alt="" />
            </div>
          </>
        )}
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Delete Event</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this event?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleClose}>
            No
          </button>
          <button
            className="yes"
            onClick={() => {
              handleClose();
              deleteEvent();
            }}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AllEvents;
