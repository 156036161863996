import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import OccupcatService from "../../services/occupcat.service";
import AuthService from "../../services/auth.service";
import CoursecatService from "../../services/coursecat.service";
import PathwayService from "../../services/pathway.service";
import { useAsyncError } from "react-router";
import CoursesList from "../../common/CoursesList";
import { Link, useLocation } from "react-router-dom";
import GuestuserService from "../../services/guestuser.service";

const Filters = ({
  handleOccCat,
  handleCourseCat,
  handleStartDtFilter,
  handleEndDtFilter,
}) => {
  const location = useLocation();
  const [occupations, setOccupations] = useState([]);
  const [courseCats, setCourseCats] = useState([]);
  const [pathways, setPathways] = useState([]);

  const [selectedOccCat, setSelectedOccCat] = useState("");
  const [selectedCorsCat, setSelectedCorsCat] = useState("");

  const [strDate, setStrDate] = useState("");
  const [edDate, setEdDate] = useState("");

  useEffect(() => {
    getOccupationCat();
    getCourseCats();
  }, []);

  const getOccupationCat = () => {
    GuestuserService.getEstoreOccupCatGuest()
      .then((response) => {
        setOccupations(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getCourseCats = () => {
    GuestuserService.getEstoreCatGuest()
      .then((response) => {
        setCourseCats(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <div className="box-outer left">
        <div className="box-inner">
          <div className="filter">
            <div className="filter-heading">
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.432"
                  height="15.819"
                  viewBox="0 0 24.503 22.236"
                >
                  <g id="setting-_1_" transform="translate(-21.408 468.876)">
                    <path
                      id="Path_12194"
                      data-name="Path 12194"
                      d="M365.507-468.8A3.342,3.342,0,0,0,363-465.566a3.082,3.082,0,0,0,.366,1.541,3.339,3.339,0,0,0,3.338,1.781,3.279,3.279,0,0,0,2.016-1.008,3.023,3.023,0,0,0,.742-1.175,3.384,3.384,0,0,0-.935-3.646,4.2,4.2,0,0,0-1.426-.731A4.3,4.3,0,0,0,365.507-468.8Z"
                      transform="translate(-323.75 0)"
                    />
                    <path
                      id="Path_12195"
                      data-name="Path 12195"
                      d="M22.212-426.671a1.244,1.244,0,0,0-.428.261,1.076,1.076,0,0,0,.235,1.786l.277.136h6.863c5.281,0,6.916-.016,7.083-.063a1.188,1.188,0,0,0,.705-.736,1.117,1.117,0,0,0-.507-1.275l-.245-.146-6.895-.01C24.86-426.723,22.337-426.707,22.212-426.671Z"
                      transform="translate(0 -39.955)"
                    />
                    <path
                      id="Path_12196"
                      data-name="Path 12196"
                      d="M28.6-319.677a3.464,3.464,0,0,0-2.236,1.734l-.225.434h-1.88a8.787,8.787,0,0,0-2.074.078,1.114,1.114,0,0,0,0,2.037,8.787,8.787,0,0,0,2.074.078h1.88l.225.428a3.339,3.339,0,0,0,3.15,1.8,3.343,3.343,0,0,0,2.988-2.309,2.628,2.628,0,0,0,.136-1.019,2.593,2.593,0,0,0-.141-1.029,3.359,3.359,0,0,0-2.131-2.142A3.734,3.734,0,0,0,28.6-319.677Z"
                      transform="translate(-0.111 -141.345)"
                    />
                    <path
                      id="Path_12197"
                      data-name="Path 12197"
                      d="M278.32-276.916a1.113,1.113,0,0,0,.094,2.058c.136.042,1.661.052,4.931.042l4.732-.016.23-.157a1.109,1.109,0,0,0-.178-1.933,38.806,38.806,0,0,0-4.91-.078A39.328,39.328,0,0,0,278.32-276.916Z"
                      transform="translate(-242.883 -181.854)"
                    />
                    <path
                      id="Path_12198"
                      data-name="Path 12198"
                      d="M280.146-170.548a3.075,3.075,0,0,0-1.88.966,3.127,3.127,0,0,0-1,2.142,3.272,3.272,0,0,0,.935,2.5,3.089,3.089,0,0,0,2.209,1.008,3.24,3.24,0,0,0,1.708-.334,3.779,3.779,0,0,0,1.447-1.463l.183-.376,1.807-.005c2.11,0,2.215-.016,2.544-.4a1.073,1.073,0,0,0-.308-1.713l-.277-.136h-3.782l-.063-.188a3.569,3.569,0,0,0-1.588-1.687A3.532,3.532,0,0,0,280.146-170.548Z"
                      transform="translate(-242.489 -282.716)"
                    />
                    <path
                      id="Path_12199"
                      data-name="Path 12199"
                      d="M22.06-127.864a1.075,1.075,0,0,0-.575,1.243,1.2,1.2,0,0,0,.789.8c.178.047,1.473.063,4.758.063,5.124,0,4.889.016,5.249-.4a1.073,1.073,0,0,0-.308-1.713L31.7-128h-9.36Z"
                      transform="translate(-0.042 -323.071)"
                    />
                  </g>
                </svg>
              </span>
              <span className="name">Filters</span>
            </div>
            <div className="filter-body">
              <div className="filter-list">
                <div className="filter-item">
                  <div className="filter-name">
                    <span>Occupation Category</span>
                  </div>
                  <div className="filter-input">
                    <div className="input-box">
                      <select
                        name="occupations"
                        id="filterOccupCat"
                        // value={selectedOccCat}
                        // onChange={(e) => {
                        //   handleOccCat(e);
                        //   setSelectedOccCat(e.target.value);
                        // }}
                      >
                        <option value={0}>Select</option>
                        {Array.isArray(occupations)
                          ? occupations.map((occupation) => {
                              const { id, category_name } = occupation;
                              return (
                                <option key={id} value={id}>
                                  {category_name}
                                </option>
                              );
                            })
                          : null}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="filter-item">
                  <div className="filter-name">
                    <span>Course Category</span>
                  </div>
                  <div className="filter-input">
                    <div className="input-box">
                      <select
                        name="courses"
                        id="filterCourseCat"
                        // value={selectedCorsCat}
                        // onChange={(e) => {
                        //   handleCourseCat(e);
                        //   setSelectedCorsCat(e.target.value);
                        // }}
                        issearchable="true"
                      >
                        <option value={0}>Select</option>
                        {Array.isArray(courseCats)
                          ? courseCats.map((courseCat) => {
                              const { id, course_catename } = courseCat;
                              return (
                                <option
                                  key={id}
                                  value={
                                    location.pathname.includes("/Catalog")
                                      ? id
                                      : id
                                  }
                                >
                                  {course_catename}
                                </option>
                              );
                            })
                          : null}
                      </select>
                    </div>
                  </div>
                </div>

                <p id="clearFilter">Clear Filter</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Filters;
