import React, { useState, useEffect } from "react";
import "./OrgManagement.css";
import DeleteIcon from "../../../assets/images/incorrect.png";
import { Link } from "react-router-dom";
import { Modal, Button, Alert } from "react-bootstrap";

import OrganisationService from "../../../services/organisation.service";
import SubOrganisationService from "../../../services/suborganisation.service";
import AuthService from "../../../services/auth.service";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ManageOrganisation = ({ permissions, roleid }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [did, setDid] = useState("");
  const [dataUpload, setDataUpload] = useState(false);
  const [message, setMessage] = useState("");

  const [uid, setUid] = useState("");

  const handleShow = (e) => {
    setShow(true);
    setDid(e.currentTarget.getAttribute("data-bs-target"));
  };

  const [currentOrg, setCurrentOrg] = useState({
    id: "",
    orgname: "",
    logo: "",
  });

  const [subOrgs, setSubOrgs] = useState([]);

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    setUid(user.user);

    OrganisationService.getOrg(user.user)
      .then((response) => {
        setCurrentOrg({
          id: response.data[0].id,
          orgname: response.data[0].organization_name,
          industry: response.data[0].Industry,
          logo: response.data[0].logo,
        });
      })
      .catch((e) => {
        console.log(e);
      });

    SubOrganisationService.getSubOrg(user.user)
      .then((response) => {
        setSubOrgs(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const deleteSubOrgs = (e) => {
    e.preventDefault();

    SubOrganisationService.deleteSubOrg(uid, did)
      .then((response) => {
        toast.success("Sub-Organisation deleted Successfully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setShow(false);
        setSubOrgs(subOrgs.filter((subOrg) => subOrg.id !== did));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <div className="container-fluid org box-outer">
        <ToastContainer />
        <div className="row">
          <div className="heading">
            <h1>Manage Organization</h1>
          </div>
          <div className="col-xl-3 col-lg-3"></div>
          <div className="col-xl-6 col-lg-6">
            <div className="main-org">
              <div className="org-title">
                <h2>Current Organization</h2>
              </div>
              <div className="logo">
                {(permissions && permissions.includes("mo3")) ||
                roleid === "1" ? (
                  <>
                    <Link to={"/EditOrg"}>
                      <img
                        src={
                          "https://lmsdemo.aeonsoftware.net/cit/" +
                          currentOrg.logo
                        }
                        className="img-fluid"
                        title="Edit Organisation"
                        alt=""
                      />
                    </Link>
                  </>
                ) : (
                  <>
                    <img
                      src={
                        "https://lmsdemo.aeonsoftware.net/cit/" +
                        currentOrg.logo
                      }
                      className="img-fluid"
                      title="Edit Organisation"
                      alt=""
                    />
                  </>
                )}
              </div>
              <div className="orgname">
                <span>{currentOrg.orgname}</span>
              </div>
            </div>
            <div className="sub-org">
              <div className="org-title">
                <h2>Sub-Organization</h2>
              </div>
              <div className="org-flex">
                {Array.isArray(subOrgs)
                  ? subOrgs.map((subOrg, index) => {
                      return (
                        <div className="org-item" key={index}>
                          <div className="logo">
                            {(permissions && permissions.includes("mo3")) ||
                            roleid === "1" ? (
                              <>
                                <Link
                                  to={"/EditSubOrg"}
                                  state={{ id: subOrg.id }}
                                >
                                  <img
                                    src={
                                      "https://lmsdemo.aeonsoftware.net/cit/" +
                                      subOrg.logo
                                    }
                                    className="img-fluid"
                                    title="Edit Sub-Organisation"
                                    alt=""
                                  />
                                </Link>
                              </>
                            ) : (
                              <>
                                <img
                                  src={
                                    "https://lmsdemo.aeonsoftware.net/cit/" +
                                    subOrg.logo
                                  }
                                  className="img-fluid"
                                  title="Edit Sub-Organisation"
                                  alt=""
                                />
                              </>
                            )}

                            {(permissions && permissions.includes("mo4")) ||
                            roleid === "1" ? (
                              <>
                                <div
                                  className="deleteIcon"
                                  onClick={handleShow}
                                  data-bs-target={subOrg.id}
                                >
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="12"
                                      height="12"
                                      viewBox="0 0 16 16"
                                    >
                                      <g
                                        id="incorrect"
                                        transform="translate(-42.855 468.853)"
                                      >
                                        <path
                                          id="Path_11894"
                                          data-name="Path 11894"
                                          d="M49.823-468.8a8.028,8.028,0,0,0-6.569,5.449,8.179,8.179,0,0,0-.353,3.393,8.029,8.029,0,0,0,6.978,7.057,10.241,10.241,0,0,0,1.858.015,8.016,8.016,0,0,0,6.306-4.453,8.037,8.037,0,0,0,.394-6.076,8.068,8.068,0,0,0-4.2-4.7,8.362,8.362,0,0,0-2.5-.707A11.241,11.241,0,0,0,49.823-468.8Zm-1.438,4.4c.068.034.653.586,1.3,1.229L50.855-462l1.175-1.172c.642-.643,1.224-1.195,1.287-1.229a.752.752,0,0,1,.92.162.754.754,0,0,1,.161.921c-.034.064-.586.646-1.227,1.289L52-460.855l1.171,1.172c.642.646,1.2,1.236,1.235,1.308a.986.986,0,0,1,.008.7,1.245,1.245,0,0,1-.405.4,1.019,1.019,0,0,1-.676-.019c-.071-.038-.661-.594-1.3-1.236l-1.175-1.172-1.171,1.172c-.646.643-1.227,1.2-1.291,1.229a.752.752,0,0,1-.92-.162.754.754,0,0,1-.161-.921c.034-.064.586-.646,1.227-1.293l1.171-1.172-1.171-1.176c-.642-.643-1.194-1.225-1.227-1.289a.821.821,0,0,1,.488-1.142A.935.935,0,0,1,48.385-464.4Z"
                                        />
                                      </g>
                                    </svg>
                                  </span>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="orgname">
                            <span>{subOrg.subornanization_name}</span>
                          </div>
                        </div>
                      );
                    })
                  : null}
                <div className="add-item">
                  {(permissions && permissions.includes("mo2")) ||
                  roleid === "1" ? (
                    <>
                      <Link to="/AddSubOrg">
                        <div className="logo">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="36"
                            height="36"
                            viewBox="0 0 28.345 28.343"
                          >
                            <g id="add" transform="translate(-72.15 439.827)">
                              <path
                                id="Path_13773"
                                data-name="Path 13773"
                                d="M85.636-439.709a2.284,2.284,0,0,0-.663.447c-.594.594-.578.385-.578,6.375v5.3h-5.3c-5.99,0-5.782-.015-6.375.578a1.889,1.889,0,0,0,0,2.7c.594.594.385.578,6.375.578h5.3v5.3c0,5.99-.015,5.782.578,6.375a1.889,1.889,0,0,0,2.7,0c.594-.594.578-.385.578-6.375v-5.3h5.3c5.99,0,5.782.015,6.375-.578a1.889,1.889,0,0,0,0-2.7c-.594-.594-.385-.578-6.375-.578h-5.3v-5.3c0-5.99.015-5.782-.578-6.375A1.921,1.921,0,0,0,85.636-439.709Z"
                                transform="translate(0 0)"
                              />
                            </g>
                          </svg>
                        </div>
                        <div className="orgname">
                          <span>Add Sub-Organisation</span>
                        </div>
                      </Link>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3"></div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Delete Sub-Organisation</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this Sub-Organisation?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleClose}>
            No
          </button>
          <button className="yes" onClick={deleteSubOrgs}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManageOrganisation;
