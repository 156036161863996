import React, { useState, useEffect } from "react";
import { Form, Alert } from "react-bootstrap";
import "./Onboarding.css";
import "./Responsive.css";
import AuthService from "../../../services/auth.service";
import OccupcatService from "../../../services/occupcat.service";
import StdOnboardService from "../../../services/stdonboard.service";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const StepTwo = ({ prevStep, nextStep }) => {
  const [validated, setValidated] = useState(false);
  const [dataUpload, setDataUpload] = useState(false);
  const [message, setMessage] = useState("");
  const [uploaded, setUploaded] = useState(false);
  const [stepSts, setStepSts] = useState(false);
  const [logoImg, setLogoImg] = useState("");
  const [checked, setChecked] = useState("");
  const [checkedSubOccup, setCheckedSubOccup] = useState("");
  const [currentUser, setCurrentUser] = useState();

  let navigate = useNavigate();

  const [requiredtimemonth, setRequiredtimemonth] = useState("");
  const [requiredtimehour, setRequiredtimehour] = useState("");

  const [selectedOccup, setSelectedOccup] = useState([]);
  const [selectedSubOccup, setSelectedSubOccup] = useState([]);
  const [selectedPrio, setSelectedPrio] = useState([]);

  const [occSts, setOccSts] = useState("");

  const [priorityOpts, setPriorityOpts] = useState([
    {
      id: "1",
      name: "Tracking",
    },
    {
      id: "2",
      name: "Completing assigned course",
    },
    {
      id: "3",
      name: "Finding new course",
    },
  ]);

  const handleCourseTimeChange = (e) => {
    setRequiredtimemonth(e.target.value);
  };

  const handleCourseTimePerDayChange = (e) => {
    setRequiredtimehour(e.target.value);
  };

  const setNotification = (e) => {
    e.preventDefault();
    const data = {
      requiredtimemonth: requiredtimemonth,
      requiredtimehour: requiredtimehour,
    };

    StdOnboardService.onboardStepTwo(data)
      .then((resp) => {
        toast.success("Notification set successfully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    setCurrentUser(user);

    setOccSts(user.occsts);

    StdOnboardService.getOnboardStepData()
      .then((resp) => {
        setRequiredtimemonth(resp.data.requiredtimemonth);
        setRequiredtimehour(resp.data.requiredtimehour);

        OccupcatService.getOccupCat(user.user)
          .then((response) => {
            const value = response.data.filter(
              (item) => item.id === resp.data.occupation
            );
            setSelectedOccup(value);
          })
          .catch((e) => {
            console.log(e);
          });

        OccupcatService.getSubOccupCat(resp.data.occupation)
          .then((response) => {
            if (resp.data !== false) {
              const selectedSubOccup = resp.data.suboccupation.split(",");
              const value = response.data.filter((item) =>
                selectedSubOccup.includes(item.id)
              );
              setSelectedSubOccup(value);
            }
          })
          .catch((e) => {
            console.log(e);
          });

        if (resp.data.priority !== "") {
          const value = priorityOpts.filter(
            (item) => item.id === resp.data.priority
          );
          setSelectedPrio(value);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  const prevPage = (e) => {
    e.preventDefault();
    prevStep();
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    event.preventDefault();

    setTimeout(() => {
      if (occSts === "1") {
        navigate("/Dashboard");
      } else {
        StdOnboardService.onboardSts()
          .then((resp) => {
            toast.success("Onboarding completed successfully!", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });

            localStorage.setItem("occsts", JSON.stringify("1"));

            navigate("/Dashboard");
            window.location.reload();
          })
          .catch((e) => console.log(e));
      }
    }, 3000);
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <ToastContainer />
        <div className="row learner-onboard mb-3">
          <div className="col-xl-6 col-lg-6">
            <div className="occup">
              <Form.Group controlId="formBasicOrgName">
                <Form.Label>Selected Occupation</Form.Label>
                <div className="multiple-input active admin">
                  <div className="addedCat grid">
                    {Array.isArray(selectedOccup)
                      ? selectedOccup.map((occup, index) => {
                          return (
                            <div className="addedCat-item" key={index}>
                              <span>{occup.category_name}</span>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              </Form.Group>
              <Form.Group controlId="formBasicOrgName">
                <Form.Label>Interested in</Form.Label>
                <div className="multiple-input active admin">
                  <div className="addedCat grid">
                    {Array.isArray(selectedSubOccup)
                      ? selectedSubOccup.map((suboccup) => {
                          return (
                            <>
                              <div className="addedCat-item">
                                <span>{suboccup.subcategory_name}</span>
                              </div>
                            </>
                          );
                        })
                      : null}
                  </div>
                </div>
              </Form.Group>
              <Form.Group controlId="formBasicOrgName">
                <Form.Label>Selected Priority</Form.Label>
                <div className="multiple-input active admin">
                  <div className="addedCat grid">
                    {Array.isArray(selectedPrio)
                      ? selectedPrio.map((prio, index) => {
                          return (
                            <div
                              className="addedCat-item max-content"
                              key={index}
                            >
                              <span>{prio.name}</span>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              </Form.Group>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="priority">
              <label htmlFor="">Set Goals</label>
              <div className="goals">
                <Form.Group controlId="formBasicOrgName">
                  <Form.Label>When do you aim to finish course?</Form.Label>
                  <div className="multiple-input active admin">
                    <div className="assign-req input-box">
                      <div className="select">
                        <select
                          name=""
                          id=""
                          value={requiredtimemonth}
                          onChange={(e) => {
                            handleCourseTimeChange(e);
                          }}
                        >
                          <option value="0">Select</option>
                          <option value="1">1 Month</option>
                          <option value="2">2 Month</option>
                          <option value="3">3 Month</option>
                          <option value="4">4 Month</option>
                          <option value="5">5 Month</option>
                          <option value="6">6 Month</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group controlId="formBasicOrgName">
                  <Form.Label>How much time per day can you spend?</Form.Label>
                  <div className="multiple-input active admin">
                    <div className="assign-req input-box">
                      <div className="select">
                        <select
                          name=""
                          id=""
                          value={requiredtimehour}
                          onChange={(e) => {
                            handleCourseTimePerDayChange(e);
                          }}
                        >
                          <option value="0">Select</option>
                          <option value="1">1 Hour</option>
                          <option value="2">2 Hour</option>
                          <option value="3">3 Hour</option>
                          <option value="4">4 Hour</option>
                          <option value="5">5 Hour</option>
                          <option value="6">6 Hour</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </div>
              <div className="flex-row">
                <input type="submit" className="skip" value="Skip" />
                <input
                  type="submit"
                  className="set"
                  value="Set Notifications"
                  onClick={(e) => {
                    setNotification(e);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex-row">
          <input
            type="submit"
            className="onboard-btn-o"
            value="Back"
            onClick={prevPage}
          />
          <input type="submit" className="onboard-btn-f" value="Next" />
        </div>
      </Form>
    </>
  );
};

export default StepTwo;
