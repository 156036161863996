import React from "react";
import { render } from "react-dom";
import jsPDF from "jspdf";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import certificate from "../../assets/images/GAA-Certificate.png";
import { renderToStaticMarkup } from "react-dom/server";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useEffect } from "react";
import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";
import { useState } from "react";
import { useParams } from "react-router-dom";
import CourseService from "../../services/course.service";
// import "./certificate.css";

const styles = StyleSheet.create({
  view: {
    position: "relative",
  },
  certificate: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
  },
  logo: {
    position: "absolute",
    top: "25px",
    width: "100px",
    height: "100px",
    margin: "0 auto",
    marginBottom: "25px",
  },
  textone: {
    position: "absolute",
    top: "210px",
    left: "47%",
    // transform: "translateX(-50%)",
    fontSize: "16px",
    textAlign: "center",
    color: "#004B98",
  },
  texttwo: {
    position: "absolute",
    top: "275px",
    left: "42%",
    // transform: "translateX(-50%)",
    fontSize: "16px",
    textAlign: "center",
    color: "#004B98",
  },
});

const CertificatePreview = ({ currentUser, courseName }) => (
  <Document>
    <Page size="A4" style={styles.page} orientation="landscape">
      <View style={styles.view}>
        <Image src={certificate} style={styles.certificate} />
        <Text style={styles.textone}>
          {currentUser.first_name} {currentUser.last_name}
        </Text>
        <Text style={styles.texttwo}>{courseName}</Text>
      </View>
    </Page>
  </Document>
);

const Certificate = () => {
  const { cid } = useParams();

  const [currentUser, setCurrentUser] = useState("");

  const [courseName, setCourseName] = useState("");

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    CourseService.getCourseById(user.user, cid)
      .then((res) => {
        setCourseName(res.data.course_info[0].name);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <div>
      <PDFViewer width="100%" height="700px">
        <CertificatePreview currentUser={currentUser} courseName={courseName} />
      </PDFViewer>
      <PDFDownloadLink
        document={
          <CertificatePreview
            currentUser={currentUser}
            courseName={courseName}
          />
        }
        fileName="example.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading..." : "Download Certificate"
        }
      </PDFDownloadLink>
    </div>
  );
};

export default Certificate;
